import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Theme } from '@mui/material';

import { ComponentNavDrawer } from '../components/ComponentNavDrawer';
import { MainWindow } from '../components/MainWindow';
import { SmallScreenWarning } from '../components/SmallScreenWarning';
import { ThemeConfigDrawer } from '../components/ThemeConfigDrawer';
import { Tutorial } from '../components/Tutorial';
import { ErrorBoundary } from '../components/ErrorBoundary';

const useStyles = makeStyles<Theme>((theme) => ({
  appRoot: {
    display: 'flex',
    height: 'calc(100vh - 112px)',
  },
  headerNavAndMain: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },
  navAndMain: {
    flex: 1,
    display: 'flex',
    minHeight: 0,
  },
  main: {
    minWidth: 0,
    minHeight: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: '#000000',
    [theme.breakpoints.up('md')]: {
      position: 'static',
    },
  },
}));

export function IndexPage() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.appRoot}>
        <ErrorBoundary>
          <div className={classes.headerNavAndMain}>
            <div className={classes.navAndMain}>
              <ComponentNavDrawer />
              <Container component="main" maxWidth="lg">
                <MainWindow />
              </Container>
            </div>
          </div>
          <ThemeConfigDrawer />
        </ErrorBoundary>
      </div>
      <SmallScreenWarning />
      <Tutorial />
    </>
  );
}
