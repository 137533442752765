import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

// @ts-ignore
import intellisenseImage from '../../../images/tutorial/intellisense.webp';
// @ts-ignore
import intellisenseCssImage from '../../../images/tutorial/intellisense-css.webp';
import { codeEditorId } from '../../MonacoThemeCodeEditor';
import { TutorialTooltip } from '../TutorialTooltip';
import { TutorialCard } from '../TutorialCard';

export function CodeEditorTutorialStep() {
  return (
    <TutorialTooltip anchorId={codeEditorId} placement="left">
      <Typography variant="h5">This is the Theme Code Editor</Typography>
      <Typography paragraph>Edit the theme directly and view changes made via the tool panel</Typography>
      <Typography>
        This site uses{' '}
        <Link href="https://microsoft.github.io/monaco-editor/" target="_blank" rel="noreferrer">
          Monaco Code Editor
        </Link>
        {', '}
        the code editor that powers VS Code
      </Typography>
    </TutorialTooltip>
  );
}

export function IntellisenseTutorialStep() {
  return (
    <>
      <TutorialCard title="Intellisense Suggestions">
        <Typography paragraph>
          Intellisense is loaded with Material-UI&apos;s <code>ThemeOptions</code> type data.
        </Typography>
        <Typography>
          Get suggestions for global <code>overrides</code> and <code>props</code>
        </Typography>
        <img src={intellisenseImage} style={{ border: '1px solid white', marginTop: 8, marginBottom: 8 }} alt="" />
        <Typography>And suggestions for CSS properties as you type</Typography>
        <img src={intellisenseCssImage} style={{ border: '1px solid white', marginTop: 8, marginBottom: 8 }} alt="" />
      </TutorialCard>
      <TutorialTooltip anchorId={codeEditorId} placement="bottom">
        <Typography paragraph>Intellisense is on!</Typography>
        <Typography>
          Press <code>Ctrl + Space</code> from the editor for code suggestions
        </Typography>
      </TutorialTooltip>
    </>
  );
}
