import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Tabs, Tab, IconButton, Theme, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MaterialUiIcon from 'mdi-material-ui/MaterialUi';
import BrushIcon from '@mui/icons-material/Brush';

import { setActiveTab } from '../state/actions';
import { RootState } from '../state/types';
import { ThemeWrapper } from './ThemeWrapper';
import { MuiComponentSamples } from './MuiComponentSamples';
import { PreviewWindow } from './PreviewWindow';
import { SavedThemes } from './SavedThemes/SavedThemes';

const useStyles = makeStyles<Theme>({
  mainWindow: {
    overflowY: 'auto',
    height: 'calc(100vh - 150px)',
  },
  navAppBar: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  componentsTabRoot: {
    backgroundColor: '#fff', // ensures transparent colors show properly
  },
  tabs: {
    flexGrow: 1,
  },
  tabFlexContainer: {
    justifyContent: 'center',
  },
});

export const previewTabId = 'preview-tab';
export const componentsTabId = 'components-tab';
export const savedThemesTabId = 'saved-themes-tab';

export function MainWindow() {
  const classes = useStyles();
  const activeTab = useSelector((state: RootState) => state.activeTab);
  const dispatch = useDispatch();
  const setTab = React.useCallback((value) => dispatch(setActiveTab(value)), [dispatch]);

  return (
    <Paper elevation={3} sx={{ height: 'calc(100vh - 112px)' }}>
      <AppBar position="sticky" color="default" className={classes.navAppBar} elevation={0}>
        {/* <Hidden lgUp> */}
        <IconButton onClick={() => dispatch({ type: 'TOGGLE_COMPONENT_NAV' })}>
          <MaterialUiIcon />
        </IconButton>
        {/* </Hidden> */}
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          onChange={(event, value) => setTab(value)}
          classes={{
            root: classes.tabs,
            flexContainer: classes.tabFlexContainer,
          }}
        >
          <Tab label="Preview" value="preview" id={previewTabId} />
          <Tab label="Components" value="components" id={componentsTabId} />
          <Tab label="Saved Themes" value="saved" id={savedThemesTabId} />
        </Tabs>
        {/* <Hidden smUp> */}
        <IconButton onClick={() => dispatch({ type: 'TOGGLE_THEME_CONFIG' })}>
          <BrushIcon />
        </IconButton>
        {/* </Hidden> */}
      </AppBar>
      <div className={classes.mainWindow}>
        {activeTab === 'preview' && <PreviewWindow />}
        {activeTab === 'components' && (
          <div className={classes.componentsTabRoot}>
            <ThemeWrapper>
              <MuiComponentSamples />
            </ThemeWrapper>
          </div>
        )}
        {activeTab === 'saved' && <SavedThemes />}
      </div>
    </Paper>
  );
}
