import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { createStore } from './state/createStore';
import { IndexPage } from './pages';

// Instantiating store in `wrapRootElement` handler ensures:
//  - there is fresh store for each SSR page
//  - it will be called only once in browser, when React mounts
const { store, persistor } = createStore();

export function ThemeCreator() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IndexPage />
      </PersistGate>
    </Provider>
  );
}
