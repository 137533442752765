import TableChartIcon from '@mui/icons-material/TableChart';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PaletteIcon from '@mui/icons-material/Palette';

export default {
  defaultTheme: 'light',
  routing: [
    { path: 'app/', name: 'Main', Icon: TableChartIcon },
    { path: 'app/playground', name: 'Playground', Icon: AddBoxIcon },
    { path: 'app/theme', name: 'Theme creator', Icon: PaletteIcon },
  ],
  apiBase: 'http://localhost:3003',
  apiSuffix: '/',
  requestTimeout: 120000,
  recaptchaSiteKey: '6LdIQ_4ZAAAAAL9S1Kx-nERP_LnNUkw5WUlzeiH7',
};
