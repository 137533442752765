import React from 'react';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const date = '16 Mar, 2019';
const rows = [
  createData(0, date, 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
  createData(1, date, 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
  createData(2, date, 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(3, date, 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
  createData(4, date, 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles<Theme>((theme) => ({ seeMore: { marginTop: theme.spacing(3) } }));

export function Orders() {
  const classes = useStyles();
  return (
    <>
      <Tooltip title={`<Typography color="primary" variant="h6">`} placement="left" arrow>
        <Typography variant="h6" color="primary" gutterBottom>
          Recent Orders
        </Typography>
      </Tooltip>
      <Tooltip title="<Table>" placement="top" arrow>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Ship To</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell align="right">Sale Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.shipTo}</TableCell>
                <TableCell>{row.paymentMethod}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Tooltip>
      <div className={classes.seeMore}>
        <Tooltip title={`<Link color="primary">`} placement="left" arrow>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link color="primary" href="#" onClick={preventDefault}>
            See more orders
          </Link>
        </Tooltip>
      </div>
    </>
  );
}
