import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider, Theme } from '@mui/material';
import Paper from '@mui/material/Paper';

import { RootState } from '../state/types';

interface ThemeWrapperProps {
  children: React.ReactNode | React.ReactNodeArray;
}

const useStyles = makeStyles<Theme>((theme) => ({
  themeContainer: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100%',
  },
}));

/**
 *
 * CssBa
 *
 */
export function ThemeContainer({ children }: ThemeWrapperProps) {
  const classes = useStyles();
  return (
    <Paper className={classes.themeContainer} elevation={0} square>
      {children}
    </Paper>
  );
}

/**
 *
 * Wraps example content in the dynamically controlled theme
 * set by the theme editor sidebar
 */
export function ThemeWrapper({ children }: ThemeWrapperProps) {
  const themeObject = useSelector((state: RootState) => state.themeObject);

  return (
    <ThemeProvider theme={themeObject}>
      <ThemeContainer>{children}</ThemeContainer>
    </ThemeProvider>
  );
}
