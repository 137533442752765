import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Hidden, Dialog, Typography, DialogContent, Slide, Theme, Button, darken } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// @ts-ignore
import hereBeDragonsImage from '../images/herebedragons.webp';
import { loadFonts } from '../state/actions';
import { RootState } from '../state/types';

const useStyles = makeStyles<Theme>((theme) => ({
  dialogPaper: {
    backgroundColor: darken(theme.palette.error.dark, 0.5),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  exitButtonArea: {
    textAlign: 'center',
    marginBottom: 32,
    '& > *': {
      fontFamily: '"Press Start 2P"',
    },
  },
}));

// const Transition = React.forwardRef<TransitionProps>((props, ref) => (
//   <Slide direction="up" ref={ref} {...props} children={props.children} />
// ));

export function SmallScreenWarning() {
  const classes = useStyles();
  const warningSeen = useSelector((state: RootState) => state.mobileWarningSeen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: 'WARNING_SCREEN_SEEN' });
  };

  useEffect(() => {
    loadFonts(['Press Start 2P']);
  }, []);

  return (
    <Hidden smUp>
      <Dialog
        fullScreen
        open={!warningSeen}
        onClose={handleClose}
        TransitionComponent={Slide}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h5">Material-UI Theme Creator</Typography>
          <Typography variant="h6">You are using a small screen</Typography>
          <div>
            <Typography align="center" paragraph>
              This is a developer tool, designed for use on large screens
            </Typography>
            <Typography align="center">You will likely have issues viewing content or using the tools.</Typography>
          </div>
          <img src={hereBeDragonsImage} alt="Here Be Dragons... (for small screens)" width="75%" />
          <div className={classes.exitButtonArea}>
            <Typography align="center">Warning to all who enter</Typography>
            <Button variant="outlined" onClick={handleClose}>
              Here be dragons
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Hidden>
  );
}
