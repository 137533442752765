import React, { useEffect } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { TextField, InputAdornment, Popover, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ThemeValueChangeEvent } from '../ThemeTools/events';
import { MaterialColorPicker } from './MaterialColorPicker';
import { colorFromString } from './utils';
import type { ColorPickerProps } from './types';

/**
 * Creates the ChromePicker and MaterialColorPicker and
 * handles/formats events from ChromePicker
 */
function ColorPicker({ color, onChangeComplete }: ColorPickerProps) {
  const [inputValue, setInputValue] = React.useState<string | undefined>('#fff');
  useEffect(() => {
    setInputValue(color);
  }, [color]);

  const handleChange = (colorObj: ColorResult, event: React.ChangeEvent<HTMLInputElement>): string => {
    console.log('ColorPicker', event);

    if (colorObj.rgb.a === 1) {
      setInputValue(colorObj.hex);

      return colorObj.hex;
    }

    const rgb = `rgba(${colorObj.rgb.r},${colorObj.rgb.g},${colorObj.rgb.b},${colorObj.rgb.a})`;
    setInputValue(rgb);

    return rgb;
  };

  const handleChangeComplete = (colorObj: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    const colorString = handleChange(colorObj, event);
    onChangeComplete(colorString);
  };

  return (
    <>
      <MaterialColorPicker color={inputValue} onChangeComplete={onChangeComplete} />
      <ChromePicker color={inputValue} onChange={handleChange} onChangeComplete={handleChangeComplete} />
    </>
  );
}

const useStyles = makeStyles<Theme>((/* theme: Theme */) => ({
  popoverPaper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    alignItems: 'center',
  },
  colorSampleAdornment: {
    width: '1em',
    height: '1em',
    border: '1px solid grey',
  },
}));

type ColorInputProps = {
  label: string;
  color: string;
  onColorChange: (color: string) => void;
};

/**
 * The base TextField input for selecting colors.
 * onClick opens a popover with components to help pick colors
 */
export function ColorInput({ label, color, onColorChange }: ColorInputProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleOpenPopover = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    document.dispatchEvent(ThemeValueChangeEvent());
  };

  const handleColorChange = (value: string) => onColorChange(value);

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedText = event.clipboardData.getData('text');
    const colorObj = colorFromString(pastedText);

    if (colorObj) {
      handleColorChange(colorObj);
    }
  };

  const popoverOpen = Boolean(anchorEl);
  return (
    <div>
      <TextField
        label={label}
        onClick={handleOpenPopover}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div className={classes.colorSampleAdornment} style={{ backgroundColor: color }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        size="small"
        value={color}
        onPaste={handlePaste}
      />
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{ className: classes.popoverPaper }}
        disableAutoFocus
        disableEnforceFocus
      >
        <ColorPicker color={color} onChangeComplete={handleColorChange} />
      </Popover>
    </div>
  );
}
