import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { setThemeOption, removeThemeOption } from '../../../state/actions';
import { useThemeValueInfo } from '../../../state/selectors';
import { ColorInput } from '../../ColorInput';

const useStyles = makeStyles<Theme>({
  resetButton: { textTransform: 'capitalize' },
  disabledButton: { fontStyle: 'italic' },
});

export function PaletteInput({ label, path }) {
  const classes = useStyles();
  const themeValueInfo = useThemeValueInfo(path);
  const dispatch = useDispatch();

  const handleColorChange = useCallback((color) => dispatch(setThemeOption(path, color)), [dispatch, path]);

  const handleReset = useCallback(() => dispatch(removeThemeOption(path)), [dispatch, path]);

  return (
    <Grid container justifyContent="space-between" alignItems="flex-end">
      <Grid item>
        <ColorInput label={label} color={themeValueInfo.value} onColorChange={handleColorChange} />
      </Grid>
      <Grid item>
        <Button
          size="small"
          disabled={!themeValueInfo.modifiedByUser}
          classes={{
            root: classes.resetButton,
            disabled: classes.disabledButton,
          }}
          onClick={handleReset}
        >
          {themeValueInfo.modifiedByUser ? 'Reset' : 'auto'}
        </Button>
      </Grid>
    </Grid>
  );
}
