import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

import { RootState } from '../../state/types';
import { incrementTutorialStep, decrementTutorialStep, toggleTutorial } from '../../state/actions';
// import stepList from './Steps';

const stepListLength = 12;

export function TutorialStepButton({ variant }) {
  const tutorialStep = useSelector((state: RootState) => state.tutorialStep);
  const dispatch = useDispatch();

  const handleNext = useCallback(() => {
    dispatch(incrementTutorialStep());
  }, [dispatch]);

  const handlePrev = useCallback(() => {
    dispatch(decrementTutorialStep());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(toggleTutorial());
  }, [dispatch]);

  if (variant === 'next' && tutorialStep === stepListLength - 1) {
    return <Button onClick={handleClose}>Finish</Button>;
  }

  return (
    <Button
      disabled={
        (variant === 'prev' && tutorialStep === 0) || (variant === 'next' && tutorialStep === stepListLength - 1)
      }
      // eslint-disable-next-line no-nested-ternary
      onClick={variant === 'next' ? handleNext : variant === 'prev' ? handlePrev : undefined}
    >
      {variant === 'next' && 'Next'}
      {variant === 'prev' && 'Prev'}
    </Button>
  );
}
