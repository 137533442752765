import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import useLocalStorage from 'react-use/esm/useLocalStorage';

import config from '../../config';
import { useChangeTheme } from '../../theme/ThemeProvider';
import { ThemeSwitch } from '../Switch/ThemeSwitch';

export function AppDrawer(): JSX.Element {
  const [themeValue, setThemeValue] = useLocalStorage<string>('theme');
  const location = useLocation();
  const handleChangeTheme = useChangeTheme();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget),
    [],
  );
  const handleCloseNavMenu = React.useCallback(() => setAnchorElNav(null), []);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}> */}
          {/*  {location.state as string} */}
          {/* </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {config.routing.map(({ name, path, Icon }) => (
                <Link
                  key={name}
                  to={path}
                  component={NavLink}
                  variant="body1"
                  color={location.pathname === path ? 'primary' : 'inherit'}
                  sx={{ textDecoration: 'none' }}
                  state={name}
                >
                  <MenuItem onClick={handleCloseNavMenu} selected={location.state === name}>
                    <Icon color={location.state === name ? 'primary' : undefined} />
                    <Typography sx={{ mx: 1 }} textAlign="center">
                      {name}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {location.state as string}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {config.routing.map(({ name, path, Icon }) => (
              <Link
                key={name}
                to={path}
                component={NavLink}
                color={location.pathname === path ? 'secondary' : 'inherit'}
                sx={{ textDecoration: 'none' }}
                state={name}
              >
                <Button
                  variant="contained"
                  onClick={handleCloseNavMenu}
                  sx={{ mx: 1 }}
                  startIcon={<Icon />}
                  color={location.state === name ? 'secondary' : undefined}
                >
                  {name}
                </Button>
              </Link>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center', textAlign: 'center', marginRight: '15px' }}>
            <ThemeSwitch
              checked={themeValue === 'dark'}
              onChange={() => {
                handleChangeTheme();
                setThemeValue(themeValue === 'light' ? 'dark' : 'light');
              }}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
