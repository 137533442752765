import React from 'react';
import { AppBarExample } from './AppBar';
import { AccordionExample } from './Accordion';
import { TypographyExample } from './Typography';
import { ButtonsExample } from './Button';
import { CheckboxesExample } from './Checkboxes';
import { FabExample } from './FAB';
import { RadioExample } from './RadioGroup';
import { TabsExample } from './Tabs';
import { CardExample } from './Card';
import { ProgressExample } from './Progress';
import { SnackbarExample } from './Snackbar';
import { AvatarExample } from './Avatar';
import { BadgeExample } from './Badge';
import { ChipExample } from './Chip';
import { BottomNavigationExample } from './BottomNavigation';
import { DialogExample } from './Dialog';
import { IconExample } from './Icon';
import { MenuExample } from './Menu';
import { SelectExample } from './Select';
import { SliderExample } from './Slider';
import { StepperExample } from './Stepper';
import { SwitchExample } from './Switch';
import { TextFieldExample } from './TextField';
import { TableExample } from './Table';
import { ListExample } from './List';
import { TooltipExample } from './Tooltip';

// items skipped for now:
// Button Group
// Date/Time inputs
// Transfer List
// Drawer

// Alphabetically sorted list of all Material-UI component examples
export default [
  {
    id: 'Accordion',
    title: 'Accordion',
    component: <AccordionExample />,
    docs: 'https://mui.com/components/accordion/',
  },
  {
    id: 'Appbar',
    title: 'App Bar',
    component: <AppBarExample onDrawerButtonClick={undefined} />,
    docs: 'https://mui.com/components/app-bar/',
  },
  {
    id: 'Avatar',
    title: 'Avatar',
    component: <AvatarExample />,
    docs: 'https://mui.com/components/avatars/',
  },
  {
    id: 'Badge',
    title: 'Badge',
    component: <BadgeExample />,
    docs: 'https://mui.com/components/badges/',
  },
  {
    id: 'BottomNavigation',
    title: 'Bottom Navigation',
    component: <BottomNavigationExample />,
    docs: 'https://mui.com/components/bottom-navigation/',
  },
  {
    id: 'Buttons',
    title: 'Buttons',
    component: <ButtonsExample />,
    docs: 'https://mui.com/components/buttons/',
  },
  {
    id: 'Card',
    title: 'Card',
    component: <CardExample />,
    docs: 'https://mui.com/components/cards/',
  },
  {
    id: 'Checkboxes',
    title: 'Checkboxes',
    component: <CheckboxesExample />,
    docs: 'https://mui.com/components/checkboxes/',
  },
  {
    id: 'Chip',
    title: 'Chip',
    component: <ChipExample />,
    docs: 'https://mui.com/components/chips/',
  },
  {
    id: 'Dialog',
    title: 'Dialog',
    component: <DialogExample />,
    docs: 'https://mui.com/components/dialogs/',
  },
  {
    id: 'FloatingActionButton',
    title: 'Floating Action Button',
    component: <FabExample />,
    docs: 'https://mui.com/components/floating-action-button/',
  },
  {
    id: 'Icon',
    title: 'Icon',
    component: <IconExample />,
    docs: 'https://mui.com/components/icons/',
  },
  {
    id: 'List',
    title: 'List',
    component: <ListExample />,
    docs: 'https://mui.com/components/lists/',
  },
  {
    id: 'Menu',
    title: 'Menu',
    component: <MenuExample />,
    docs: 'https://mui.com/components/menus/',
  },
  {
    id: 'Progress',
    title: 'Progress',
    component: <ProgressExample />,
    docs: 'https://mui.com/components/progress/',
  },
  {
    id: 'Radio',
    title: 'Radio',
    component: <RadioExample />,
    docs: 'https://mui.com/components/radio-buttons/',
  },
  {
    id: 'Select',
    title: 'Select',
    component: <SelectExample />,
    docs: 'https://mui.com/components/selects/',
  },
  {
    id: 'Slider',
    title: 'Slider',
    component: <SliderExample />,
    docs: 'https://mui.com/components/slider/',
  },
  {
    id: 'Snackbar',
    title: 'Snackbar',
    component: <SnackbarExample />,
    docs: 'https://mui.com/components/snackbars/',
  },
  {
    id: 'Stepper',
    title: 'Stepper',
    component: <StepperExample />,
    docs: 'https://mui.com/components/steppers/',
  },
  {
    id: 'Switch',
    title: 'Switch',
    component: <SwitchExample />,
    docs: 'https://mui.com/components/switches/',
  },
  {
    id: 'Table',
    title: 'Table',
    component: <TableExample />,
    docs: 'https://mui.com/components/tables/',
  },
  {
    id: 'Tabs',
    title: 'Tabs',
    component: <TabsExample />,
    docs: 'https://mui.com/components/tabs/',
  },
  {
    id: 'TextField',
    title: 'TextField',
    component: <TextFieldExample />,
    docs: 'https://mui.com/components/text-fields/',
  },
  {
    id: 'Tooltip',
    title: 'Tooltip',
    component: <TooltipExample />,
    docs: 'https://mui.com/components/tooltips/',
  },
  {
    id: 'Typography',
    title: 'Typography',
    component: <TypographyExample />,
    docs: 'https://mui.com/components/typography/',
  },
];
