import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';

import { appReducer } from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['themeId', 'themeOptions', 'savedThemes'],
};

// in production, persist the mobileWarningSeen value so mobile users don't see the popup again on reload
if (process.env.NODE_ENV === 'production') {
  persistConfig.whitelist.push('mobileWarningSeen');
}

const persistedReducer = persistReducer(persistConfig, appReducer);

export function createStore() {
  const store = reduxCreateStore(persistedReducer, applyMiddleware(thunk));
  const persistor = persistStore(store);
  // persistor.purge()
  return { store, persistor };
}
