import {
  ToolPanelTutorialStep,
  PaletteTutorialStep,
  FontTutorialStep,
  TypographyTutorialStep,
  SnippetsTutorialStep,
} from './toolPanelSteps';
import { ComponentsTabTutorialStep } from './componentSamplesSteps';
import { IntellisenseTutorialStep, CodeEditorTutorialStep } from './codeEditorSteps';
import { PreviewTabTutorialStep, SiteSamplesTutorialStep } from './previewWindowSteps';
import { SavedThemesTabTutorialStep, AddNewThemesTutorialStep } from './savedThemesSteps';

export default [
  ToolPanelTutorialStep,
  PaletteTutorialStep,
  FontTutorialStep,
  TypographyTutorialStep,
  SnippetsTutorialStep,
  IntellisenseTutorialStep,
  CodeEditorTutorialStep,
  PreviewTabTutorialStep,
  SiteSamplesTutorialStep,
  ComponentsTabTutorialStep,
  SavedThemesTabTutorialStep,
  AddNewThemesTutorialStep,
];
