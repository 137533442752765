import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { Link } from '@mui/material';

import { toggleTutorial } from '../../state/actions';

export function TutorialLink({ children }) {
  const dispatch = useDispatch();
  const handleToggle = useCallback(() => dispatch(toggleTutorial()), [dispatch]);

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <Link onClick={handleToggle}>{children}</Link>;
}

export function TutorialButton() {
  const dispatch = useDispatch();
  const handleToggle = useCallback(() => dispatch(toggleTutorial()), [dispatch]);

  return <Button onClick={handleToggle}>Tutorial</Button>;
}
