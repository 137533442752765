import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Portal from '@mui/material/Portal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { RootState } from '../../state/types';
import { setActiveTab, resetTutorialStep, toggleTutorial } from '../../state/actions';
import stepList from './Steps';
import { TutorialStepButton } from './TutorialStepButton';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    zIndex: 5000,
    position: 'relative',
  },
  title: {
    position: 'absolute',
    top: 0,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'baseline',
    '& > *': {
      margin: `0 ${theme.spacing()}px`,
    },
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '1.5rem',
  },
  closeIcon: {
    fontSize: '2rem',
  },
}));

export function TutorialContent() {
  const classes = useStyles();
  const previewWindowTab = useSelector((state: RootState) => state.activeTab);
  const step = useSelector((state: RootState) => state.tutorialStep);
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(toggleTutorial()), [dispatch]);

  useEffect(
    () =>
      function onEnd() {
        // reset to the originally opened tab
        dispatch(setActiveTab(previewWindowTab));
        dispatch(resetTutorialStep());
      },
    [dispatch, previewWindowTab],
  );

  const CurrentStep = stepList[step];
  return (
    <Portal>
      <div className={classes.root}>
        <Backdrop open>
          <div className={classes.title}>
            <TutorialStepButton variant="prev" />
            <Typography variant="h3">Tutorial</Typography>
            <Typography>{`(${step + 1}/${stepList.length})`}</Typography>
            <TutorialStepButton variant="next" />
          </div>

          <Button
            onClick={handleClose}
            endIcon={<CloseIcon className={classes.closeIcon} />}
            className={classes.closeButton}
          >
            Close
          </Button>
          <CurrentStep />
        </Backdrop>
      </div>
    </Portal>
  );
}

export function Tutorial() {
  const open = useSelector((state: RootState) => state.tutorialOpen);
  return open ? <TutorialContent /> : null;
}
