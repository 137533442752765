import React, { useEffect } from 'react';
import {
  teal,
  red,
  purple,
  pink,
  deepPurple,
  deepOrange,
  orange,
  indigo,
  amber,
  lime,
  lightBlue,
  lightGreen,
  green,
  blue,
  cyan,
  grey,
  blueGrey,
  brown,
  yellow,
} from '@mui/material/colors';
import { Theme, Tooltip, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { decomposeColor, recomposeColor, rgbToHex, hslToRgb } from '@mui/system/colorManipulator';

import type { ColorPickerProps } from './types';

const colors: Record<string, Record<string, string>> = {
  teal,
  red,
  purple,
  pink,
  deepPurple,
  deepOrange,
  orange,
  indigo,
  amber,
  lime,
  lightBlue,
  lightGreen,
  green,
  blue,
  cyan,
  grey,
  blueGrey,
  brown,
  yellow,
};

const muiHues = [
  'teal',
  'red',
  'purple',
  'pink',
  'deepPurple',
  'deepOrange',
  'orange',
  'indigo',
  'amber',
  'lime',
  'lightBlue',
  'lightGreen',
  'green',
  'blue',
  'cyan',
  'grey',
  'blueGrey',
  'brown',
  'yellow',
];

const muiShades = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700'];

// record of {"#hexcode": ["hue", "shade"]}
// for all colors in material-ui's spec
const muiColorByHex: Record<string, [string, string]> = {};
// eslint-disable-next-line no-plusplus
for (let i = 0; i < muiHues.length; i++) {
  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < muiShades.length; j++) {
    muiColorByHex[colors[muiHues[i]][muiShades[j]]] = [muiHues[i], muiShades[j]];
  }
}

const paletteWidth = 400;
const colorTypeWidth = paletteWidth / muiHues.length;
const colorStrengthWidth = paletteWidth / muiShades.length;

const useStyles = makeStyles<Theme>((theme) => ({
  paletteContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '1.5em',
    '&$colorType': {
      alignItems: 'flex-end',
    },
  },
  colorType: {},
  colorItem: {
    transition: theme.transitions.create('height'),
  },
}));

export function MaterialColorPicker({ color, onChangeComplete }: ColorPickerProps) {
  const [hue, setHue] = React.useState('red');
  const [shade, setShade] = React.useState<string | null>(null);
  const classes = useStyles();

  useEffect(() => {
    // if incoming color maps to a Material UI color, change the input to match
    if (!color) {
      return;
    }

    let hexColor;

    const decomposed = decomposeColor(color);

    switch (decomposed.type) {
      case 'rgba':
        // @ts-ignore
        hexColor = rgbToHex(recomposeColor({ type: 'rgb', values: decomposed.values.slice(0, 3) }));
        break;
      case 'rgb':
        hexColor = rgbToHex(color);
        break;
      case 'hsla':
        // @ts-ignore
        hexColor = rgbToHex(hslToRgb(recomposeColor({ type: 'hsl', values: decomposed.values.slice(0, 3) })));
        break;
      case 'hsl':
        hexColor = rgbToHex(hslToRgb(color));
        break;
      default:
        hexColor = color;
    }

    const mappedColor = muiColorByHex[hexColor];

    if (mappedColor) {
      setHue(mappedColor[0]);
      setShade(mappedColor[1]);
    }
  }, [color]);

  return (
    <div>
      <div>
        <div className={`${classes.paletteContainer} ${classes.colorType}`}>
          {muiHues.map((muiHue) => (
            <Tooltip title={muiHue} placement="top" key={muiHue} TransitionComponent={Collapse} arrow>
              <div
                role="button"
                aria-label="select"
                tabIndex={0}
                onKeyPress={() => {
                  /**/
                }}
                style={{
                  height: hue === muiHue ? '1.5em' : '1em',
                  width: colorTypeWidth,
                  backgroundColor: colors[muiHue]['500'],
                }}
                className={classes.colorItem}
                onClick={() => setHue(muiHue)}
              />
            </Tooltip>
          ))}
        </div>
        <div className={classes.paletteContainer}>
          {muiShades.map((muiShade) => (
            <Tooltip
              title={muiShade}
              key={`${hue ?? 'red'}-${muiShade}`}
              placement="bottom"
              TransitionComponent={Collapse}
              arrow
            >
              <div
                role="button"
                aria-label="select"
                tabIndex={0}
                onKeyPress={() => {
                  /**/
                }}
                style={{
                  height: shade === muiShade ? '1.5em' : '1em',
                  width: colorStrengthWidth,
                  backgroundColor: colors[hue ?? 'red'][muiShade],
                }}
                className={classes.colorItem}
                onClick={() => {
                  setShade(muiShade);
                  onChangeComplete(colors[hue ?? 'red'][muiShade]);
                }}
              />
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
}
