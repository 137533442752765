/* eslint-disable react/require-default-props,react/destructuring-assignment */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Editor, { useMonaco, BeforeMount, OnMount, OnValidate } from '@monaco-editor/react';
import dirtyJson from 'dirty-json';
import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { initializeIcons } from '@fluentui/react';
import Box from '@mui/material/Box';

import { useToggle } from './hooks';
import { ToolBar } from './components/tool-bar';
import { downloadJsonFile, minifyJsonString, prettifyJsonString, parseJsonSchemaString } from './utils';

initializeIcons();

interface JSONEditorProps {
  theme?: string;
  path?: string;
  defaultValue?: string;
  value?: string;
  schemaValue?: string | Record<string, any>;
  onChange?: (val: string) => void;
}

interface RefObject extends Monaco.editor.ICodeEditor {
  _domElement?: HTMLElement;
}

export function JSONEditor(props: JSONEditorProps): JSX.Element {
  const { theme = 'dark', schemaValue, path = '', onChange } = props;

  const [value, setValue] = useState<string | undefined>(props.defaultValue);
  useEffect(() => {
    if (props.defaultValue && onChange) {
      onChange(props.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const monaco = useMonaco();
  // const [errors, setErrors] = useState<string[]>([]);
  const [isAutoPrettifyOn, toggleAutoPrettifyOn] = useToggle(false);

  const [isValidJson, setIsValidJson] = useState<boolean>(false);
  const editorRef = useRef<RefObject | null>(null);

  const updateEditorLayout = useCallback(() => {
    if (!editorRef.current) {
      return;
    }
    // Initialize layout's width and height
    // Type BUG: editor.IDimension.width & editor.IDimension.height should be "number"
    // but it needs to have "auto" otherwise layout can't be updated;
    // @ts-ignore
    editorRef.current.layout({ width: 'auto', height: 'auto' });

    const editorEl = editorRef.current._domElement;

    if (!editorEl) {
      return;
    }

    const { width, height } = editorEl.getBoundingClientRect();
    // update responsive width and height
    editorRef.current.layout({ width, height });
  }, []);

  const handleJsonSchemasUpdate = useCallback(() => {
    monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      allowComments: true,
      enableSchemaRequest: !!schemaValue,
      schemas: schemaValue
        ? [
            {
              uri: 'http://json-schema.org/draft-07/schema', // id of the first schema
              fileMatch: ['*'], // associate with our model
              schema: typeof schemaValue === 'string' ? parseJsonSchemaString(schemaValue) : schemaValue,
            },
          ]
        : undefined,
    });
  }, [schemaValue, monaco]);

  const handleEditorPrettify = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.getAction('editor.action.formatDocument').run();
    }
  }, []);

  const handleEditorUpdateValue = useCallback((val?: string) => {
    if (!editorRef.current) {
      return;
    }
    editorRef.current.setValue(val || '');
    if (val) {
      editorRef.current.getAction('editor.action.formatDocument').run();
    }
  }, []);

  const handleClearClick = () => editorRef.current?.setValue('');

  const handleEditorWillMount: BeforeMount = () => handleJsonSchemasUpdate();

  const handleEditorDidMount: OnMount = (editor) => {
    editorRef.current = editor;

    editor.getModel()?.updateOptions({ tabSize: 2, insertSpaces: false });
    updateEditorLayout();

    window.addEventListener('resize', () => {
      // automaticLayout isn't working
      // https://github.com/suren-atoyan/monaco-react/issues/89#issuecomment-666581193
      // clear current layout
      updateEditorLayout();
    });

    // need to use formatted prettify json string
    if (value) {
      handleEditorUpdateValue(prettifyJsonString(value));
    }
  };

  useEffect(() => {
    handleEditorUpdateValue(value);
  }, [value, handleEditorUpdateValue]);

  useEffect(() => {
    handleJsonSchemasUpdate();
  }, [schemaValue, handleJsonSchemasUpdate]);

  useEffect(() => {
    updateEditorLayout();
  }, [updateEditorLayout]);

  useEffect(() => {
    if (isAutoPrettifyOn) {
      handleEditorPrettify();
    }
  }, [isAutoPrettifyOn, handleEditorPrettify]);

  const handleEditorValidation: OnValidate = useCallback((markers) => {
    const errorMessage = markers.map(({ startLineNumber, message }) => `line ${startLineNumber}: ${message}`);
    const hasContent = editorRef.current?.getValue();
    const hasError: boolean = errorMessage.length > 0;
    setIsValidJson(!!hasContent && !hasError);
    console.log(errorMessage);
    // setErrors(errorMessage);
  }, []);

  const handleMinifyClick = () => {
    if (!editorRef.current) {
      return;
    }
    const val = editorRef.current.getValue();
    const minifiedValue = minifyJsonString(val);
    editorRef.current.setValue(minifiedValue);
  };

  const handleUploadClick = (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const result = fileReader.result as string;
      handleEditorUpdateValue(result);
    };
    fileReader.readAsText(file);
  };

  const handleDownloadClick = () => {
    const val = editorRef.current?.getValue();
    if (val) {
      downloadJsonFile(val);
    }
  };

  const handleEditorChange = useCallback(
    (val) => {
      if (isAutoPrettifyOn) {
        handleEditorPrettify();
      }

      if (onChange) {
        onChange(val);
      }
    },
    [isAutoPrettifyOn, handleEditorPrettify, onChange],
  );

  const handleFixClick = () => {
    if (editorRef.current) {
      const val = editorRef.current.getValue();
      const fixedValue = val && dirtyJson.parse(val);
      const formattedValue = fixedValue && prettifyJsonString(JSON.stringify(fixedValue));
      editorRef.current.setValue(formattedValue);
    }
  };

  return (
    <Box sx={{ height: '100%', borderRadius: '4px' }}>
      <ToolBar
        isValidJson={isValidJson}
        isAutoPrettifyOn={isAutoPrettifyOn}
        onAutoPrettifyChange={toggleAutoPrettifyOn}
        onClearClick={handleClearClick}
        onDownloadClick={handleDownloadClick}
        onMinifyClick={handleMinifyClick}
        onPrettifyClick={handleEditorPrettify}
        onUploadClick={handleUploadClick}
        onFixClick={handleFixClick}
      />
      <Box
        sx={{
          height: 'calc(100% - 44px)',
          flexGrow: 1,
          alignItems: 'stretch',
          borderRadius: '4px',
        }}
      >
        <Editor
          language="json"
          path={path}
          options={{
            automaticLayout: true,
            autoClosingBrackets: 'always',
            autoClosingQuotes: 'always',
            formatOnPaste: true,
            formatOnType: true,
            scrollBeyondLastLine: false,
            theme: theme === 'dark' ? 'vs-dark' : 'vs',
          }}
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
          beforeMount={handleEditorWillMount}
          onValidate={handleEditorValidation}
        />
      </Box>
    </Box>
  );
}
