import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { withStyles, ClassNameMap } from '@mui/styles';

import { resetSiteData } from '../state/actions';

function ClearStorageButton() {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(resetSiteData());
    window.location.reload();
  }, [dispatch]);

  return (
    <Button variant="contained" color="primary" size="large" onClick={handleClick}>
      Reset Site Data
    </Button>
  );
}

type Props = {
  classes: ClassNameMap<'errorRoot'>;
};
type State = { hasError: boolean; error: Error | null };

class ErrorBoundaryBase extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error /* , errorInfo: any */) {
    // You can also log the error to an error reporting service
    console.log('Caught Error', error);
  }

  render() {
    const { classes, children } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.errorRoot}>
          <Typography variant="h2">Something went wrong, causing the app to crash</Typography>
          <Typography variant="h1" gutterBottom>
            :(
          </Typography>
          <Typography variant="h5" gutterBottom>
            This likely is caused by an error on the ThemeOptions object
          </Typography>
          <Typography variant="h5" gutterBottom>
            This can be cleared up by wiping the saved theme data...
          </Typography>
          <Typography variant="h5" gutterBottom>
            but you will lose your saved themes. Sorry :(
          </Typography>
          <Typography variant="h6" gutterBottom>
            Click the button below to reset your local storage data for this site
          </Typography>
          <pre>
            <code>{JSON.stringify(error?.message, null, 2)}</code>
          </pre>
          <ClearStorageButton />
        </div>
      );
    }

    return children;
  }
}

export const ErrorBoundary = withStyles({
  errorRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    overflowY: 'auto',
  },
})(ErrorBoundaryBase);
