import { NewSavedTheme } from '../../state/types';

const roboto = 'Roboto';
const doHyeon = 'Do Hyeon';
const openSans = 'Open Sans';
const ubuntuMono = 'Ubuntu Mono';

export const defaultThemeList: Omit<NewSavedTheme, 'lastUpdated'>[] = [
  {
    name: 'Material-UI Docs - Light',
    themeOptions: {
      palette: {
        mode: 'light',
        primary: {
          main: '#1976d2',
        },
        secondary: {
          main: 'rgb(220, 0, 78)',
        },
        background: {
          default: '#fff',
          paper: '#fff',
        },
      },
    },
    fonts: [roboto],
  },
  {
    name: 'Material-UI Docs - Dark',
    themeOptions: {
      palette: {
        mode: 'dark',
        primary: {
          main: '#90caf9',
        },
        secondary: {
          main: '#f48fb1',
        },
        background: {
          default: '#212121',
          paper: '#424242',
        },
      },
    },
    fonts: [roboto],
  },
  {
    name: 'Hacker Theme',
    themeOptions: {
      palette: {
        mode: 'dark',
        primary: {
          main: '#0f0',
        },
        background: {
          default: '#111111',
          paper: '#212121',
        },
      },
      typography: {
        fontFamily: openSans,
        h1: {
          fontFamily: ubuntuMono,
        },
        h2: {
          fontFamily: ubuntuMono,
        },
        h3: {
          fontFamily: ubuntuMono,
        },
        h4: {
          fontFamily: ubuntuMono,
        },
        h6: {
          fontFamily: ubuntuMono,
        },
        h5: {
          fontFamily: ubuntuMono,
        },
        subtitle1: {
          fontFamily: ubuntuMono,
        },
        subtitle2: {
          fontFamily: ubuntuMono,
        },
        button: {
          fontFamily: ubuntuMono,
          fontWeight: 900,
        },
        overline: {
          fontFamily: ubuntuMono,
        },
      },
    },
    fonts: [openSans, ubuntuMono],
  },
  {
    name: 'Dark Blue',
    themeOptions: {
      palette: {
        mode: 'dark',
        primary: {
          main: '#5893df',
        },
        secondary: {
          main: '#2ec5d3',
        },
        background: {
          default: '#192231',
          paper: '#24344d',
        },
      },
    },
    fonts: [roboto],
  },
  {
    name: 'Red Rover',
    themeOptions: {
      palette: {
        mode: 'dark',
        primary: {
          main: '#ff8f00',
        },
        secondary: {
          main: '#f50057',
        },
        background: {
          default: '#310000',
          paper: '#731010',
        },
      },
      typography: {
        fontFamily: doHyeon,
      },
      shape: {
        borderRadius: 16,
      },
    },
    fonts: [roboto, doHyeon],
  },
  {
    name: 'Comic Book',
    themeOptions: {
      palette: {
        mode: 'dark',
        primary: {
          main: '#bd0707',
        },
        secondary: {
          main: '#ffc510',
        },
        background: {
          default: '#4c69f6',
          paper: '#4c94f6',
        },
      },
      typography: {
        body1: {
          fontFamily: roboto,
        },
        fontFamily: 'Bangers',
        caption: {
          fontFamily: doHyeon,
        },
        overline: {
          fontFamily: doHyeon,
        },
        body2: {
          fontFamily: roboto,
        },
      },
    },
    fonts: ['Bangers', doHyeon, roboto],
  },
];
