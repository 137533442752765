import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Link,
  // useTheme,
  // useMediaQuery,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { setActiveTab } from '../state/actions';
import { RootState } from '../state/types';
import componentSamples from './MuiComponentSamples/Samples';

const drawerWidth: React.CSSProperties['width'] = 200;

const useStyles = makeStyles<Theme>((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  list: {
    // gives background to the sticky header
    backgroundColor: theme.palette.background.paper,
  },
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
}));

export const componentNavDrawerId = 'component-nav-drawer';

export function ComponentNavDrawer() {
  const classes = useStyles();
  // const theme = useTheme();
  // const permanent = useMediaQuery(theme.breakpoints.up('md'));
  const open = useSelector((state: RootState) => state.componentNavOpen);

  const dispatch = useDispatch();
  const handleClick = React.useCallback(() => {
    dispatch({ type: 'TOGGLE_COMPONENT_NAV' });
    dispatch(setActiveTab('components'));
  }, [dispatch]);

  return (
    <Drawer
      id={componentNavDrawerId}
      className={classes.drawer}
      // variant={permanent ? 'permanent' : 'temporary'}
      variant="temporary"
      classes={{ paper: classes.drawerPaper }}
      open={open}
      anchor="left"
      onClose={() => dispatch({ type: 'TOGGLE_COMPONENT_NAV' })}
    >
      <List dense className={classes.list}>
        <ListSubheader>Components</ListSubheader>
        {componentSamples.map(({ id, title }) => (
          <Link
            key={id}
            href={`#${id}`}
            variant="body1"
            onClick={handleClick}
            color={window.location.hash === `#${id}` ? 'primary' : 'inherit'}
            sx={{ textDecoration: 'none' }}
          >
            <ListItem button selected={window.location.hash === `#${id}`}>
              <ListItemText
                primary={title}
                className={classes.listItemText}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
}
