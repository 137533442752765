import React from 'react';
import Button from '@mui/material/Button';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const action = (
  <Button color="secondary" size="small">
    lorem ipsum dolorem
  </Button>
);

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    maxWidth: 600,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function SnackbarExample() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SnackbarContent message="I love snacks." action={action} />
      <SnackbarContent
        message={'I love candy. I love cookies. I love cupcakes. \
          I love cheesecake. I love chocolate.'}
        action={
          <IconButton size="small" aria-label="close" color="inherit">
            <CloseIcon />
          </IconButton>
        }
      />
      <SnackbarContent message="I love candy. I love cookies. I love cupcakes." action={action} />
      <SnackbarContent
        message={'I love candy. I love cookies. I love cupcakes. \
          I love cheesecake. I love chocolate.'}
        action={action}
      />
    </div>
  );
}
