import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles<Theme>({ disabledText: { fontStyle: 'italic' } });

const getLetterSpacingValue = (letterSpacing: string) => {
  if (letterSpacing == null || letterSpacing.endsWith('rem') || !letterSpacing.endsWith('em')) {
    return undefined;
  }
  return parseFloat(letterSpacing.slice(0, -2));
};

export function LetterSpacingInput({ value, onChange /* , property */ }) {
  const classes = useStyles();
  const [displayValue, setDisplayValue] = useState<number | undefined>(undefined);

  useEffect(() => setDisplayValue(getLetterSpacingValue(value)), [value]);

  const disabled = displayValue === undefined;

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="baseline">
        <Grid item>
          <Typography variant="caption" color="textSecondary">
            Letter Spacing:
          </Typography>
        </Grid>
        <Grid item>{!disabled && <Typography display="inline">{`${displayValue}em`}</Typography>}</Grid>
      </Grid>
      <Slider
        value={disabled ? 0 : displayValue}
        disabled={disabled}
        min={-0.1}
        max={1.5}
        step={0.01}
        onChange={(event, newDisplayValue) => setDisplayValue(newDisplayValue as number)}
        onChangeCommitted={(event, newValue) => onChange(event, `${newValue}em`)}
      />
      {disabled && (
        <Typography color="textSecondary" variant="caption" className={classes.disabledText}>
          Only em units supported. Use the code editor to configure other types.
        </Typography>
      )}
    </>
  );
}
