import React from 'react';
import Typography from '@mui/material/Typography';

import { previewTabId } from '../../MainWindow';
import { previewSizeControlsId } from '../../PreviewWindow/PreviewSizeControls';
import { previewNavTabsId } from '../../PreviewWindow/PreviewWindow';
import { TutorialTooltip } from '../TutorialTooltip';

import { useSwitchToTab } from './hooks';

export function PreviewTabTutorialStep() {
  useSwitchToTab('preview');
  return (
    <TutorialTooltip anchorId={previewTabId} placement="bottom">
      <Typography variant="h5">This is the Preview Tab</Typography>
    </TutorialTooltip>
  );
}

export function SiteSamplesTutorialStep() {
  useSwitchToTab('preview');
  return (
    <>
      <TutorialTooltip anchorId={previewNavTabsId} placement="bottom">
        Use these tabs to view your theme on a variety of site templates
      </TutorialTooltip>
      <TutorialTooltip anchorId={previewSizeControlsId} placement="right">
        View your theme on multiple screen sizes
      </TutorialTooltip>
    </>
  );
}
