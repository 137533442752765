import React from 'react';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    '& > div > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const inputProps = { readOnly: true };
const inputLabelProps = { shrink: true };

export function TextFieldExample() {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField required id="standard-required" label="Required" defaultValue="Hello World" />
        <TextField disabled id="standard-disabled" label="Disabled" defaultValue="Hello World" />
        <TextField id="standard-password-input" label="Password" type="password" autoComplete="current-password" />
        <TextField id="standard-read-only-input" label="Read Only" defaultValue="Hello World" InputProps={inputProps} />
        <TextField id="standard-number" label="Number" type="number" InputLabelProps={inputLabelProps} />
        <TextField id="standard-search" label="Search field" type="search" />
        <TextField
          id="standard-helperText"
          label="Helper text"
          defaultValue="Default Value"
          helperText="Some important text"
        />
        <TextField
          id="standard-helperText-error"
          label="Helper text error"
          defaultValue="Default Value"
          helperText="Some important text"
        />
      </div>
      <div>
        <TextField required id="filled-required" label="Required" defaultValue="Hello World" variant="filled" />
        <TextField disabled id="filled-disabled" label="Disabled" defaultValue="Hello World" variant="filled" />
        <TextField
          id="filled-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="filled"
        />
        <TextField
          id="filled-read-only-input"
          label="Read Only"
          defaultValue="Hello World"
          InputProps={inputProps}
          variant="filled"
        />
        <TextField id="filled-number" label="Number" type="number" InputLabelProps={inputLabelProps} variant="filled" />
        <TextField id="filled-search" label="Search field" type="search" variant="filled" />
        <TextField
          id="filled-helperText"
          label="Helper text"
          defaultValue="Default Value"
          helperText="Some important text"
          variant="filled"
        />
        <TextField
          id="filled-helperText-error"
          error
          label="Helper text"
          defaultValue="Default Value"
          helperText="Some important text"
          variant="filled"
        />
      </div>
      <div>
        <TextField required id="outlined-required" label="Required" defaultValue="Hello World" variant="outlined" />
        <TextField disabled id="outlined-disabled" label="Disabled" defaultValue="Hello World" variant="outlined" />
        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="outlined"
        />
        <TextField
          id="outlined-read-only-input"
          label="Read Only"
          defaultValue="Hello World"
          InputProps={inputProps}
          variant="outlined"
        />
        <TextField
          id="outlined-number"
          label="Number"
          type="number"
          InputLabelProps={inputLabelProps}
          variant="outlined"
        />
        <TextField id="outlined-search" label="Search field" type="search" variant="outlined" />
        <TextField
          id="outlined-helperText"
          label="Helper text"
          defaultValue="Default Value"
          helperText="Some important text"
          variant="outlined"
        />
        <TextField
          id="outlined-helperText-error"
          label="Error Helper text"
          error
          defaultValue="Default Value"
          helperText="Some important text"
          variant="outlined"
        />
      </div>
    </form>
  );
}
