import React from 'react';
import Typography from '@mui/material/Typography';

import { savedThemesTabId } from '../../MainWindow';
import { addThemeButtonId } from '../../SavedThemes/AddThemeButton';
import { defaultThemesId } from '../../SavedThemes/DefaultThemes';
import { savedThemeListId } from '../../SavedThemes/SavedThemeList';
import { TutorialTooltip } from '../TutorialTooltip';

import { useSwitchToTab } from './hooks';

export function SavedThemesTabTutorialStep() {
  useSwitchToTab('saved');
  return (
    <TutorialTooltip anchorId={savedThemesTabId} placement="bottom">
      <Typography variant="h5">This is the Saved Themes Tab</Typography>
    </TutorialTooltip>
  );
}

export function AddNewThemesTutorialStep() {
  // useSwitchToTab("saved")
  return (
    <>
      <TutorialTooltip anchorId={savedThemeListId} placement="right">
        <Typography>Switch between your saved themes here.</Typography>
        <Typography>You can rename, or delete them here too</Typography>
      </TutorialTooltip>
      <TutorialTooltip anchorId={defaultThemesId} placement="bottom">
        Add sample themes here to check them out
      </TutorialTooltip>
      <TutorialTooltip anchorId={addThemeButtonId} placement="top">
        Add a new blank theme here
      </TutorialTooltip>
    </>
  );
}
