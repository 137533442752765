import React from 'react';
import Typography from '@mui/material/Typography';

import { componentsTabId } from '../../MainWindow';
import { componentNavDrawerId } from '../../ComponentNavDrawer';
import { TutorialTooltip } from '../TutorialTooltip';
import { useSwitchToTab } from './hooks';

export function ComponentsTabTutorialStep() {
  useSwitchToTab('components');
  return (
    <>
      <TutorialTooltip anchorId={componentsTabId} placement="bottom">
        <Typography variant="h5">This is the Components Tab</Typography>
        <Typography>View your theme on the Material-UI components</Typography>
      </TutorialTooltip>
      <TutorialTooltip anchorId={componentNavDrawerId} placement="right">
        Click a component name to navigate to it
      </TutorialTooltip>
    </>
  );
}
