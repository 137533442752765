import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material';

import { AddressForm } from './AddressForm';
import { PaymentForm } from './PaymentForm';
import { Review } from './Review';

const useStyles = makeStyles<Theme>((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up(600 + Number.parseInt(theme.spacing(2), 10) * 2)]: {
      maxWidth: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + Number.parseInt(theme.spacing(3), 10) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Shipping address', 'Payment details', 'Review your order'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm />;
    case 1:
      return <PaymentForm />;
    case 2:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}

export function CheckoutExample() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        <Tooltip title={`<Typography color="textPrimary" variant="h4">`} placement="top" arrow>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
        </Tooltip>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <Tooltip title="<StepLabel>" placement="top" arrow>
                <StepLabel>{label}</StepLabel>
              </Tooltip>
            </Step>
          ))}
        </Stepper>

        {activeStep === steps.length ? (
          <>
            <Tooltip title={`<Typography color="textPrimary" variant="h5">`} placement="left" arrow>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
            </Tooltip>
            <Tooltip title={`<Typography color="textPrimary" variant="subtitle1">`} placement="left" arrow>
              <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order confirmation, and will send you an update when
                your order has shipped.
              </Typography>
            </Tooltip>
          </>
        ) : (
          <>
            {getStepContent(activeStep)}
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Tooltip title={`<Button variant="text">`} arrow>
                  <Button onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                </Tooltip>
              )}
              <Tooltip title={`<Button color="primary" variant="contained">`} arrow>
                <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                  {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                </Button>
              </Tooltip>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
}
