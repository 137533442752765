import React from 'react';

import { SnippetItem } from './SnippetItem';
import { snippets } from './snippets';

export function SnippetTools() {
  return (
    <>
      {snippets.map((snippet) => (
        <SnippetItem snippet={snippet} key={snippet.title} />
      ))}
    </>
  );
}
