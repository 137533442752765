import React from 'react';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { PaletteMode } from '@mui/material';
import useLocalStorage from 'react-use/esm/useLocalStorage';

import { ThemeProvider } from './theme/ThemeProvider';
import { RootRouter } from './router/RootRouter';
import config from './config';

export function App() {
  const [value] = useLocalStorage('theme', config.defaultTheme);

  return (
    <HelmetProvider>
      <ThemeProvider defaultTheme={value as PaletteMode}>
        <SnackbarProvider autoHideDuration={3000}>
          <RootRouter />
        </SnackbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}
