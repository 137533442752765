import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';

import { AppDrawer } from '../components/Drawer';
import { Main } from '../layouts/Main/Main';
import { Playground } from '../layouts/Playground';
import { ThemeCreator } from '../layouts/ThemeCreator';

function NotFoundPage() {
  return (
    <main style={{ padding: '1rem' }}>
      <p>There&apos;s nothing here!</p>
    </main>
  );
}

export function RootRouter() {
  return (
    <BrowserRouter>
      <Box>
        <AppDrawer />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            <Route path="app/" element={<Main />} />
            <Route path="app/playground" element={<Playground />} />
            <Route path="app/theme" element={<ThemeCreator />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
}
