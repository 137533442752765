import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material';

const useStyles = makeStyles<Theme>((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

export function MainFeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.image})` }}>
      <img style={{ display: 'none' }} src={post.image} alt={post.imageText} />
      <div className={classes.overlay} />
      <Grid container>
        <Grid item md={6}>
          <div className={classes.mainFeaturedPostContent}>
            <Tooltip title={`<Typography color="textPrimary" variant="h3">`} placement="left" arrow>
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                {post.title}
              </Typography>
            </Tooltip>
            <Tooltip title={`<Typography color="textPrimary" variant="h5">`} placement="left" arrow>
              <Typography variant="h5" color="inherit" paragraph>
                {post.description}
              </Typography>
            </Tooltip>
            <Tooltip title={`<Link color="primary" variant="subtitle1">`} placement="left" arrow>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link variant="subtitle1" href="#">
                {post.linkText}
              </Link>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}
