import React from 'react';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { toggleTutorial } from '../../state/actions';
import { TutorialStepButton } from './TutorialStepButton';

const useStyles = makeStyles<Theme>({
  tutorialTooltipContentRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  tutorialTooltipCloseButton: {
    alignSelf: 'flex-end',
    '& svg': {
      fontSize: '1.2em',
    },
  },
  tutorialTooltipActions: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tutorialTooltipContent: {
    paddingLeft: 16,
    paddingRight: 16,
  },
});

function TooltipContents({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.tutorialTooltipContentRoot}>
      <IconButton
        size="small"
        onClick={() => dispatch(toggleTutorial())}
        className={classes.tutorialTooltipCloseButton}
      >
        <CloseIcon />
      </IconButton>
      <div className={classes.tutorialTooltipContent}>{children}</div>
      <div className={classes.tutorialTooltipActions}>
        <TutorialStepButton variant="prev" />
        <TutorialStepButton variant="next" />
      </div>
    </div>
  );
}

function TutorialTooltipBase({ anchorId, children, ...props }) {
  return (
    document.getElementById(anchorId) && (
      <Tooltip
        {...props}
        open
        arrow
        title={<TooltipContents>{children}</TooltipContents>}
        PopperProps={{
          anchorEl: document.getElementById(anchorId),
          disablePortal: true,
          // modifiers: {
          //   preventOverflow: {
          //     boundariesElement: 'viewport',
          //   },
          // },
        }}
      >
        <div />
      </Tooltip>
    )
  );
}

export const TutorialTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    fontSize: '1rem',
    maxWidth: 'none',
    border: `4px solid ${theme.palette.primary.main}`,
  },
  arrow: {
    color: theme.palette.primary.main,
    fontSize: '2em',
  },
}))(TutorialTooltipBase);
