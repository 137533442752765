import React from 'react';
import { create, Jss } from 'jss';
import {
  CacheProvider as EmotionCacheProvider,
  EmotionCache,
  ThemeProvider as EmotionThemeProvider,
} from '@emotion/react';
import createCache from '@emotion/cache';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import createTheme from '@mui/material/styles/createTheme';
import MuiThemeProvider from '@mui/styles/ThemeProvider';
import useTheme from '@mui/styles/useTheme';
import jssPreset from '@mui/styles/jssPreset';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import MuiStylesProvider from '@mui/styles/StylesProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { PaletteMode, Theme } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';

type State = { paletteType: PaletteMode };
type Action = { type: 'changeTheme'; payload: PaletteMode };
type Reducer = (prevState: State, action: Action) => State;

const ThemeDispatchContext = React.createContext<React.Dispatch<Action> | null>(null);

type Props = {
  defaultTheme: PaletteMode;
  children: React.ReactChild;
};

export function ThemeProvider(props: Props): JSX.Element {
  const { defaultTheme, children } = props;

  const [jss, setJss] = React.useState<Jss>();
  const [emotionCache, setEmotionCache] = React.useState<EmotionCache>();

  function createJssStyles(ref) {
    if (ref && !jss) {
      const createdJssWithRef = create({ ...jssPreset(), insertionPoint: ref });
      setJss(createdJssWithRef);
    }
  }

  function setEmotionStyles(ref) {
    if (ref && !emotionCache) {
      const createdEmotionWithRef = createCache({ key: 'test', container: ref });
      setEmotionCache(createdEmotionWithRef);
    }
  }

  function setShadowRefs(ref) {
    createJssStyles(ref);
    setEmotionStyles(ref);
  }

  const themeInitialOptions = { paletteType: defaultTheme };

  const [themeOptions, dispatch] = React.useReducer<Reducer, State>(
    (state: State, action: Action): State => {
      if (action.type === 'changeTheme') {
        return { ...state, paletteType: action.payload };
      }

      return state;
    },
    themeInitialOptions,
    (state) => state,
  );

  const memoizedTheme = React.useMemo(() => {
    const theme = createTheme({ palette: { mode: themeOptions.paletteType } });

    return responsiveFontSizes(theme);
  }, [themeOptions.paletteType]);

  const generateClassName = createGenerateClassName({ seed: 'ES' });

  return (
    <>
      <div ref={setShadowRefs} />
      {jss && emotionCache && (
        <MuiThemeProvider theme={memoizedTheme}>
          <EmotionThemeProvider theme={memoizedTheme}>
            <MuiStylesProvider jss={jss} generateClassName={generateClassName}>
              <EmotionCacheProvider value={emotionCache}>
                <ThemeDispatchContext.Provider value={dispatch}>
                  <GlobalStyles styles={{ body: { margin: 0 } }} />
                  <CssBaseline />
                  {children}
                </ThemeDispatchContext.Provider>
              </EmotionCacheProvider>
            </MuiStylesProvider>
          </EmotionThemeProvider>
        </MuiThemeProvider>
      )}
    </>
  );
}

export const useChangeTheme = (): (() => void) => {
  const dispatch = React.useContext(ThemeDispatchContext);
  const theme = useTheme<Theme>();

  return React.useCallback(() => {
    if (dispatch) {
      dispatch({ type: 'changeTheme', payload: theme.palette.mode === 'light' ? 'dark' : 'light' });
    }
  }, [dispatch, theme.palette.mode]);
};
