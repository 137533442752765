import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonBase, Grid, Popover, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { addNewDefaultTheme } from '../../state/actions';
import { NewSavedTheme } from '../../state/types';
import { defaultThemeList } from './defaultThemeList';
import { ThemeThumbnail } from './ThemeThumbnail';

const useStyles = makeStyles<Theme>((theme) => ({
  buttonRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  thumbnailContainer: {
    position: 'relative',
    '&:hover $hoverArea': {
      display: 'flex',
    },
  },
  hoverArea: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backdropFilter: 'blur(2px) saturate(30%) brightness(40%)',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'none',
  },
  templatePopover: {
    padding: theme.spacing(2),
  },
  templateContainer: {
    flex: 1,
    flexGrow: 1,
    overflowX: 'auto',
  },
}));

export const defaultThemesId = 'default-themes';

export function DefaultThemes() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickTheme = useCallback(
    (newTheme: NewSavedTheme) => {
      dispatch(addNewDefaultTheme(newTheme));
    },
    [dispatch],
  );

  const open = Boolean(anchorEl);
  const popoverId = open ? 'default-themes-popover' : undefined;

  return (
    <>
      <Button id={defaultThemesId} variant="outlined" onClick={handleClickButton}>
        Example Templates
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.templatePopover }}
      >
        <Grid container spacing={2} wrap="nowrap" className={classes.templateContainer}>
          {defaultThemeList.map((t) => (
            <Grid item key={t.name} onClick={() => handleClickTheme(t as NewSavedTheme)}>
              <ButtonBase className={classes.buttonRoot}>
                <div className={classes.thumbnailContainer}>
                  <ThemeThumbnail themeOptions={t.themeOptions} />
                  <div className={classes.hoverArea}>
                    <Typography>Click to add</Typography>
                  </div>
                </div>

                <Typography variant="subtitle1">{t.name}</Typography>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
}
