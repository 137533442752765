import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Theme, Grid, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { RootState } from '../../state/types';
import { SavedThemeItem } from './SavedThemeItem/SavedThemeItem';
import { SavedThemeList } from './SavedThemeList';
import { AddThemeButton } from './AddThemeButton';
import { DefaultThemes } from './DefaultThemes';

const useStyles = makeStyles<Theme>((theme) => ({
  savedThemesRoot: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  savedThemes: {
    flex: 1,
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  themeActions: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(),
    },
  },
}));

export const currentThemeThumbnailId = 'current-theme-thumbnail';

function CurrentTheme() {
  const themeOptions = useSelector((state: RootState) => state.themeOptions);
  const themeId = useSelector((state: RootState) => state.themeId);
  const themeName = useSelector((state: RootState) => state.savedThemes[state.themeId].name);
  const lastUpdated = useSelector((state: RootState) => state.savedThemes[state.themeId].lastUpdated);
  return (
    <div id={currentThemeThumbnailId}>
      <SavedThemeItem name={themeName} themeOptions={themeOptions} themeId={themeId} lastUpdated={lastUpdated} large />
    </div>
  );
}

export function SavedThemes() {
  const classes = useStyles();
  return (
    <div className={classes.savedThemesRoot}>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="h4">Current Theme</Typography>
          <CurrentTheme />
          <div className={classes.themeActions}>
            <AddThemeButton />
            <DefaultThemes />
          </div>
        </Grid>
        <Divider orientation="vertical" flexItem className={classes.divider} />

        <Grid item className={classes.savedThemes}>
          <Typography variant="h4" gutterBottom>
            Saved Themes
          </Typography>
          <SavedThemeList />
        </Grid>
      </Grid>
    </div>
  );
}
