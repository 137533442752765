import React from 'react';
// import { useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

// import { Loader } from '../../components/Loader/Loader';

export function Main(): JSX.Element {
  // const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const { enqueueSnackbar } = useSnackbar();

  return (
    <Container component="main" maxWidth="lg">
      <Paper elevation={3} sx={{ padding: '25px', height: 'calc(100vh - 112px)' }}>
        Main
        {/* <Loader show={isLoading} /> */}
      </Paper>
    </Container>
  );
}
