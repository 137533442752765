import React from 'react';
import { Box, Button, Container, Paper, TextField } from '@mui/material';

import configSchema from '../../config.schema.json';
import { JSONEditor } from './components/JsonEditor';
// import MonacoCodeEditor from '../../components/MonacoCodeEditor';

export function Playground(): JSX.Element {
  const clientIdRef = React.useRef<HTMLInputElement>(null);
  const configKeyRef = React.useRef<HTMLInputElement>(null);
  const [config, setConfig] = React.useState<Record<string, any>>({});

  React.useLayoutEffect(() => {
    if (clientIdRef.current) {
      const clientId = localStorage.getItem('ClientId');
      clientIdRef.current.value = clientId || '';
    }

    if (configKeyRef.current) {
      const configKey = localStorage.getItem('ConfigKey');
      configKeyRef.current.value = configKey || '';
    }
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Paper elevation={3} sx={{ padding: '25px', height: 'calc(100vh - 112px)' }}>
        <Box
          component="form"
          autoComplete="off"
          sx={{ width: '100%', marginTop: (theme) => theme.spacing(1) }}
          onSubmit={(event) => {
            event.preventDefault();

            const data = new FormData(event.currentTarget);
            const clientData: File | string = data.get('client-id') || '';
            const key: File | string = data.get('config-key') || '';

            window.SmartChatBotApp.init({
              clientData: clientData as string,
              key: key as string,
              externalConfig: config,
            });
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="client-id"
            label="Client Id"
            name="client-id"
            type="text"
            autoComplete="off"
            inputProps={{ ref: clientIdRef }}
            onChange={(event) => localStorage.setItem('ClientId', event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="config-key"
            label="Config Key"
            type="text"
            id="config-key"
            autoComplete="off"
            inputProps={{ ref: configKeyRef }}
            onChange={(event) => localStorage.setItem('ConfigKey', event.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ margin: (theme) => theme.spacing(3, 0, 2) }}
          >
            Start
          </Button>
          <Box
            sx={{
              width: '100%',
              height: 'calc(100vh - 470px)',
              border: '1px solid rgba(0, 0, 0, 0.2)',
            }}
          >
            <JSONEditor
              schemaValue={configSchema}
              defaultValue={localStorage.getItem('ChatConf') || '{}'}
              onChange={(value) => {
                try {
                  const parsedValue = JSON.parse(value);
                  localStorage.setItem('ChatConf', value);
                  setConfig(parsedValue);
                } catch (error) {
                  console.error(error);
                }
              }}
            />
            {/* <MonacoCodeEditor /> */}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
