import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { setThemeOption, removeThemeOption } from '../../../../state/actions';
import { useThemeValueInfo } from '../../../../state/selectors';
import { FontWeightInput } from './FontWeightInput';
import { FontSizeInput } from './FontSizeInput';
import { FontFamilyInput } from './FontFamilyInput';
import { LineHeightInput } from './LineHeightInput';
import { LetterSpacingInput } from './LetterSpacingInput';
import { ThemeValueChangeEvent } from '../../events';

const useStyles = makeStyles<Theme>({
  resetButton: { textTransform: 'capitalize' },
  disabledButton: { fontStyle: 'italic' },
  inputContainer: { flex: 1 },
});

function TypographyPropertyInput({ property, ...props }) {
  switch (property) {
    case 'fontFamily':
      return <FontFamilyInput value={undefined} onChange={undefined} {...props} />;
    case 'htmlFontSize':
    case 'fontSize':
      return <FontSizeInput value={undefined} onChange={undefined} {...props} property={property} />;
    case 'fontWeight':
    case 'fontWeightLight':
    case 'fontWeightMedium':
    case 'fontWeightRegular':
    case 'fontWeightBold':
      return <FontWeightInput value={undefined} onChange={undefined} {...props} property={property} />;
    case 'letterSpacing':
      return <LetterSpacingInput value={undefined} onChange={undefined} {...props} />;
    case 'lineHeight':
      return <LineHeightInput value={undefined} onChange={undefined} {...props} />;
    default:
      return <div />;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TypographyInput({ label, variantPath, property }) {
  const classes = useStyles();
  const path = `${variantPath}.${property}`;
  const themeValueInfo = useThemeValueInfo(path);
  const dispatch = useDispatch();

  const handleValueChange = useCallback(
    (event, value) => {
      dispatch(setThemeOption(path, value));
      document.dispatchEvent(ThemeValueChangeEvent());
    },
    [dispatch, path],
  );

  const handleReset = useCallback(() => dispatch(removeThemeOption(path)), [dispatch, path]);

  return (
    <Grid container justifyContent="space-between" alignItems="baseline">
      <Grid item className={classes.inputContainer}>
        <TypographyPropertyInput property={property} value={themeValueInfo.value} onChange={handleValueChange} />
      </Grid>
      <Grid item>
        <Button
          size="small"
          disabled={!themeValueInfo.modifiedByUser}
          classes={{
            root: classes.resetButton,
            disabled: classes.disabledButton,
          }}
          onClick={handleReset}
        >
          {themeValueInfo.modifiedByUser ? 'Reset' : 'auto'}
        </Button>
      </Grid>
    </Grid>
  );
}
